import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {

  constructor() {
    super()
    this.state = {

      particleConfig: {
        num: [80, 50],
        rps: 0.1,
        radius: [5, 40],
        life: [1.5, 3],
        v: [2, 3],
        tha: [-40, 40],
        alpha: [0.6, 0],
        scale: [0.1, 1],
        position: 'all',
        color: ['red', 'blue', 'green'], // Customize the colors here
        cross: 'dead',
      }
    }
  }

  render() {
    return (
      <header id="home">
        {/* <ParticlesBg type="lines" bg={true} /> */}
        <ParticlesBg type="square" bg={true} config={{ color: ['#ff7700', '#ff7700', '#ff7700', '#ff7700', '#ff7700', '#ff7700'], }} />
        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              {/* <h1 className="responsive-headline">Rider Pro</h1> */}

              <img alt="" className="responsive-headline" src="images/app_white_icon.png" style={{  resize: "contain" }} />


            </Fade>
            {/* <Fade bottom duration={1200}>
              <h3>Experience a comprehensive automotive solution with features like nearby mechanic and retailer search for quick access, integrated chat for real-time communication, and secure item authentication using QR codes, ensuring a seamless and trustworthy user experience in a single application.</h3>
            </Fade> */}
            <hr />
            <Fade bottom duration={1200}>
              <h1>Coming Soon</h1>
            </Fade>
            <hr />
          </div>
        </div>
        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
